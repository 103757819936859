const config = {
	version: "0.0.1",
	api: {
		// URL to API
		url: "https://api.dev.easy-pro.fi",
		idleAutoLogoutTime: 7200, // 2h =70200 sec
                idleWarningTime: 120,
		refreshtime: 15,
	}
};

export default config;
